
import { Options } from 'vue-class-component'
import store from '@/store'
import Api from '@/api/index'
import { SuperVue } from '@/mixins/SuperVue'
import PagePlugin from '@/components/page-plugin/page-plugin.vue'
import Utils from '@/utils'
import CustomDialog from '@/components/custom-dialog/custom-dialog.vue'
import ScoreScaleVue from '@/components/score-scale.vue'
import giveMarkVue from './component/giveMark.vue'
import { httpRequest } from '@/plugins/index'
import Config from '@/config'
@Options({
  components: {
    PagePlugin,
    CustomDialog,
    ScoreScaleVue,
    giveMarkVue
  }
})
export default class evaluate_task extends SuperVue {
  buttonShow(name) {
    return Utils.showBtn(name)
  }

  weightTypes = [] as any
    tb = {
      page: 1,
      pageSize: 10
    }
    pageChange({ currentPage, pageSize }) {
      this.tb.page = currentPage
      this.tb.pageSize = pageSize
    }

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      const indexArr = this.weightTypes.filter(item => item.num > 0)
      const rowItem = indexArr.find(item => item.index === rowIndex)
      if (columnIndex === 0) {
        if (rowItem) {
          return {
            rowspan: rowItem.num,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    }

  // 同意
  agree_dialog = {
    title: '邀请确认',
    open: false,
    row: {} as any,
    check: false
  }
  today = Utils.dataFormat(new Date(), 'yyyy年mm月dd日')
  userName = store.state.userInfo.name

  agreeDialogConfirm() {
    if (!this.agree_dialog.check) return this.$message.error('请勾选确认承诺书')
    httpRequest
      .post('api/v1/evaluate_task/tableA/agreeOrRefuse', {
        opinion_id: this.agree_dialog.row.id,
        remarks: '',
        status: 2
      })
      .then((res: any) => {
        if (res.code === '200') {
          this.$message.success(res.message)
          this.tableA_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
          this.agree_dialog.open = false
        } else this.$message.error(res.message)
      })
  }

  // 拒绝

  refuse_dialog = {
    title: '拒绝邀请',
    open: false,
    row: {} as any,
    remarks: '',
    type: 1
  }

  refuseInit(row, type = 1) {
    this.refuse_dialog.row = row
    this.refuse_dialog.open = true
    this.refuse_dialog.type = type
    if (type === 2) this.refuse_dialog.title = '废弃原因'
  }
  // 评估弹窗废弃按钮事件
  openRefuseInit(type = 2) {
    console.log('openRefuseInit')
    this.refuse_dialog.type = type
    this.refuse_dialog.open = true
    if (type === 2) this.refuse_dialog.title = '废弃原因'
  }

  confirmRefuse() {

    if (!this.refuse_dialog.remarks) {
      return this.$message.error(
        this.refuse_dialog.type === 1 ? '请填写拒绝理由' : '请填写废弃原因'
      )
    }
    httpRequest
      .post('api/v1/evaluate_task/tableA/agreeOrRefuse', {
        opinion_id: this.refuse_dialog.row.id,
        status: this.refuse_dialog.type === 1 ? 1 : 7,
        remarks: this.refuse_dialog.remarks
      })
      .then((res: any) => {
        if (res.code === '200') {
          this.tableA_edit_dialog.open = false // 由于评估中也有废弃按钮，故在这里关闭一下
          this.$message.success(res.message)
          this.tableA_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
          this.refuse_dialog.open = false
        } else this.$message.error(res.message)
      })
  }

  // 评分标准弹窗逻辑-start
  scoreScaleData = {
    open: false,
    evaluation_criterion: '',
    weightItems: []
  }
  get_evaluation_criterion(id: number): void {
    Api.http_get_evaluation_criterion({ id: id }).then((res) => {
      if (res.code === '200') {
        console.log('s', res)
        this.scoreScaleData.evaluation_criterion = res.data.evaluation_criterion
        this.scoreScaleData.weightItems = res.data.weightItems
        // this.scoreScaleData.open = true

        if (this.giveMarkData.open === true) {
          this.scoreScaleData.open = false
        } else {
          this.scoreScaleData.open = true
        }
      }
    })
  }
  // 判断数据是否在某个闭区间
  inNumRange(min, max) {
    return function (val) {
      if (typeof val !== 'number' || isNaN(val)) return false
      if (val > max) return false
      if (val < min) return false
      return true
    }
  }
  // end
  giveMarkData = {
    open: false,
    weightItem: {},
    modifyIndex: 0
  }
  openGiveMarkVue(item, index, id: number) {
    if (this.tableA_edit_dialog.type === 'read') return
    this.giveMarkData.modifyIndex = index
    this.giveMarkData.weightItem = item
    this.giveMarkData.open = true
    this.get_evaluation_criterion(id)
    this.scoreScaleData.open = false //
  }
  giveMarkOk(item) {
    this.giveMarkData.open = false
    this.weightList[this.giveMarkData.modifyIndex].score = item.value.score
    this.weightList[this.giveMarkData.modifyIndex].opinion = item.value.opinion
    this.weightList[this.giveMarkData.modifyIndex].file = item.value.file
  }

  tableA_all_data = []

  tableA_total = 0
  tableA_search_data = {
    patent_user_name: '',
    patent_name: '',
    status: ''
  }

  rule_tableA_search_data = {}

  tableA_edit_dialog = {
    open: false,
    title: '评估',
    type: 'edit' // edit or read
  }
  tableA_edit_init(row, type) {
    this.tableA_edit_dialog.title = type === 'edit' ? '评估' : '详情'
    this.tableA_edit_dialog.type = type
    // 更新refuse_dialog数据
    this.refuse_dialog.row = row
    // 打开评估弹窗逻辑
    this.tableA_edit_dialog.type = [0, 1, 4, 6, 7].includes(row.status)
      ? 'read'
      : 'edit'
    this.get_weightList(row.id)
    this.get_weights(row.id)
  }
  tableA_edit_cancle() {
    this.tableA_edit_dialog.open = false
    this.removeDialogName()
  }
  tableA_edit_draft(status = 3) {
    const postData = {
      id: this.tableA_edit_data.id,
      opinions: this.weightList,
      suggest: this.tableA_edit_data.suggest,
      question: this.tableA_edit_data.question,
      status: status
    }
    Api.http_evaluate_tasktableA5(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tableA_edit_dialog.open = false
        this.removeDialogName()
        this.tableA_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
        this.$message.success(JSON.stringify(res.message))
      }
    })
  }
  tableA_edit_ok(status = 4) {
    const flag = this.weightList.some((item) => {
      // if (item.opinion === '' || item.opinion === null) return true
      if (item.score === '' || item.score === null) return true
    })
    if (flag) {
      this.$message.warning('请填写完整!')
      return
    }
    this.$refs.ref_tableA_edit_data.validate((valid) => {
      if (valid) {
        const postData = {
          id: this.tableA_edit_data.id,
          opinions: this.weightList,
          suggest: this.tableA_edit_data.suggest,
          question: this.tableA_edit_data.question,
          status: status
        }
        Api.http_evaluate_tasktableA5(postData).then((res) => {
          if (res.code !== '200') {
            this.$message.error(res.message)
          } else {
            this.tableA_edit_dialog.open = false
            this.removeDialogName()
            this.tableA_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
            this.$message.success(JSON.stringify(res.message))
          }
        })
      }
    })
  }
  tableA_init() {
    this.tableA_get_all_data((this.tb.page - 1) * this.tb.pageSize, this.tb.pageSize)
  }

  tableA_edit_data = {
    id: 0,
    opinions: [] as any[],
    suggest: '',
    question: '',
    person_info: {
      dept: '',
      contact: '',
      phone: '',
      duty: '',
      address: '',
      email: '',
      inventor: [] as any[]
    },
    base_info: {
      number: '',
      name: '',
      type: '',
      industry_involved: '',
      related_industry: '',
      patentee: '',
      result_type: '' as string | number,
      has_others: '' as string | number,
      others_msg: [] as any,
      digest: '',
      claim_rights_file: '' as any,
      digest_file: '' as any,
      specification_file: '' as any,
      claim_rights: '',
      specification: ''
    },
    supplement: {
      significance: '',
      question: '',
      plan: '',
      other: ''
    },
    conversion_situation: {
      conditions: '',
      expect: '' as any,
      max_price: 0,
      min_price: 0
    },
    self_info: []
  }

  rule_tableA_edit_data = {}

  weightList: any[] = []
  weights: any[] = []
  type_475 = new Map<any, string>([
    [1, '发明'],
    [2, '实用新型'],
    [3, '外观设计']
  ])
  status_477 = new Map<any, string>([
    [0, '邀请待确认'],
    [1, '已拒绝'],
    [2, '待评估'],
    [3, '草稿'],
    [4, '待审核'],
    [5, '退回'],
    [6, '已通过'],
    [7, '已废弃']
  ])
  //
  type_686 = new Map<any, string>([
    [1, '基础研究'],
    [2, '应用研究'],
    [3, '技术开发与产业化']
  ])
  type_696 = new Map<any, string>([
    [1, '否'],
    [2, '是']
  ])

  tableA_get_all_data(offset = 0, length = 10) {
    let type:any = ''
    if (this.$store.state.curRoleId === 3) type = 1 // 咨询专家
    if (this.$store.state.curRoleId === 74) type = 2 // 评价员
    const postData = {
      offset: offset || 0,
      length: length || 10,
      patent_user_name: this.tableA_search_data.patent_user_name,
      patent_name: this.tableA_search_data.patent_name,
      status: this.tableA_search_data.status,
      patent_type: 2,
      type
    }
    Api.http_evaluate_tasktableA0(postData).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.tableA_all_data = res.data.data
        this.tableA_total = res.data.total
      }
    })
  }

  /**
   * 获取专家评分列表
   */
  get_weightList(id = -1) {
    Api.http_evaluate_tasktableAweightList0({
      id: id,
      offset: 0,
      length: 999
    }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.weightList = res.data.data
        console.log('this.weightList', this.weightList)
        // 整合parent_id分类
        this.weightTypes = JSON.parse(JSON.stringify(Config.weightsTypes))
        this.weightTypes.forEach(item => { item.num = 0 })
        this.weightList.forEach((item, index) => {
          this.weightTypes.forEach(it => {
            if ((index === 0 || this.weightList[index - 1].parent_id !== item.parent_id) && item.parent_id === it.id) {
              it.index = index
            }
            if (item.parent_id === it.id) {
              it.num++
              item.parent_name = it.name
            }
          })
        })
        Api.http_evaluate_tasktableAget5({ id: id }).then((res1) => {
          if (res1.code !== '200') {
            this.$message.error(res1.message)
          } else {
            this.tableA_edit_data.id = id
            this.tableA_edit_data.opinions = res1.data.opinionList.opinions
            this.tableA_edit_data.suggest = res1.data.opinionList.suggest
            this.tableA_edit_data.question = res1.data.opinionList.question
            this.tableA_edit_data.person_info = res1.data.person_info
            this.tableA_edit_data.base_info = res1.data.base_info
            this.tableA_edit_data.supplement = res1.data.supplement
            this.tableA_edit_data.conversion_situation
              = res1.data.conversion_situation
            this.tableA_edit_data.self_info = res1.data.self_info
            this.tableA_edit_dialog.open = true
            this.setDialogName(this.tableA_edit_dialog.title)
            console.log(
              'this.tableA_edit_data.conversion_situation :>> ',
              this.tableA_edit_data.conversion_situation
            )
            if (Utils.isJSON(this.tableA_edit_data.base_info.others_msg)) {
              this.tableA_edit_data.base_info.others_msg = JSON.parse(
                this.tableA_edit_data.base_info.others_msg
              )
            }
            if (
              Utils.isJSON(this.tableA_edit_data.conversion_situation?.expect)
            ) {
              this.tableA_edit_data.conversion_situation.expect = JSON.parse(
                this.tableA_edit_data.conversion_situation.expect
              )
            }
            if (
              Utils.isJSON(this.tableA_edit_data.base_info?.claim_rights_file)
            ) {
              this.tableA_edit_data.base_info.claim_rights_file = JSON.parse(
                this.tableA_edit_data.base_info.claim_rights_file
              )
            }
            if (Utils.isJSON(this.tableA_edit_data.base_info?.digest_file)) {
              this.tableA_edit_data.base_info.digest_file = JSON.parse(
                this.tableA_edit_data.base_info.digest_file
              )
            }
            if (
              Utils.isJSON(this.tableA_edit_data.base_info?.specification_file)
            ) {
              this.tableA_edit_data.base_info.specification_file = JSON.parse(
                this.tableA_edit_data.base_info.specification_file
              )
            }
          }
        })
      }
    })
  }
  get_weights(id = -1) {
    Api.http_evaluate_tasktableAweights0({
      id: id,
      offset: 0,
      length: 999
    }).then((res) => {
      if (res.code !== '200') {
        this.$message.error(res.message)
      } else {
        this.weights = res.data.data
      }
    })
  }
  created() {
    this.tableA_init()
  }
  // 计算分数颜色
  getColorClass(score: number): string {
    if (score > 7) return 'red'
    if (score < 5) return 'orange'
    return 'blue'
  }
}
