
type WeightItem = {
  evaluation_criterion: string,
  score: number,
  file:any
  opinion: string
  weight_name: string,
  weight_items: weight_items[]
}
type weight_items = {
  created_at: string
  id: number
  score: number
  title: string
  updated_at: string
  weight_id: number
}
import { defineComponent, ref, watchEffect, PropType, getCurrentInstance } from 'vue';
import { ElMessage } from 'element-plus'
export default defineComponent({
  props: {
    modelValue: Boolean,
    weightItem: {
      type: Object as PropType<WeightItem>,
      default: () => ({
        evaluation_criterion: '',
        score: 0,
        opinion: '',
        file: [],
        weight_name: '',
        weight_items: []
      })
    },
    weightItems: Array
  },
  emits: ['update:modelValue', 'ok'],
  setup(props, context) {
    const show = ref(props.modelValue)
    watchEffect(() => { context.emit('update:modelValue', show.value) })
    watchEffect(() => { show.value = props.modelValue })
    const weight = ref<WeightItem>({} as WeightItem)
    watchEffect(() => {
      weight.value = JSON.parse(JSON.stringify(props.weightItem))
      if (!weight.value.file) weight.value.file = []
    })
    function checkOK() {
      if (weight.value.score === null) { ElMessage({ message: '请输入分数!', type: 'warning' }); return; }
      if (!weight.value.opinion && ![5, 6, 7].includes(weight.value.score)) { ElMessage({ message: '请输入理由!', type: 'warning' }); return; }
      if ((!weight.value.opinion || weight.value.opinion.length <= 10) && ![5, 6, 7].includes(weight.value.score)) { ElMessage({ message: '请输入10个字以上!', type: 'warning' }); return; }
      context.emit('ok', weight)
    }

    function getColorClass(score:number):string {
      if (score > 7) return 'red';
      if (score < 5) return 'orange';
      return 'blue';
    }
    return { show, weight, checkOK, getColorClass }
  }
})
